import { Toast } from 'vant'

export const weekDay = {
    1: '星期一',
    2: '星期二',
    3: '星期三',
    4: '星期四',
    5: '星期五',
    6: '星期六',
    7: '星期日'
}
// 0：未开1：上半场2：中场3：下半场4：加时5：点球-1：完场-10：取消-11：待定-12：腰斩-13：中断-14：推迟
export const matchStatus = {
    '0': '未',
    '1': '上半场',
    '2': '中场',
    '3': '下半场',
    '4': '加时',
    '5': '点球',
    '-1': '完',
    '-10': '取消',
    '-11': '待定',
    '-12': '腰斩',
    '-13': '中断',
    '-14': '推迟'
}
export const basketStatus = {
    0: '未开赛',
    1: '一节',
    2: '二节',
    3: '三节',
    4: '四节',
    5: '1OT',
    6: '2OT',
    7: '3OT',
    50: '中场',
    '-1': '完场',
    '-2': '待定',
    '-3': '中断',
    '-4': '取消',
    '-5': '推迟'
}
export const basketGoingStates = [1, 2, 3, 4, 5, 6, 7, 50]
export const isGoingStates = [1, 2, 3, 4, 5]
export const isGoingCompetition = (state, key = 'football') => {
    const isGoingList = key === 'football' ? isGoingStates : basketGoingStates
    return isGoingList.includes(state)
}

export const hidePhone = phone => {
    const value = phone + ''
    return (value.slice(0, 3) + '*****' + value.slice(8))
}

export function Copy (copyValue) { // 复制
    const domUrl = document.createElement('input')
    domUrl.value = copyValue
    domUrl.id = 'creatDom'
    document.body.appendChild(domUrl)
    domUrl.select() // 选择对象
    document.execCommand('Copy') // 执行浏览器复制命令
    const creatDom = document.getElementById('creatDom')
    creatDom.parentNode.removeChild(creatDom)
    Toast('复制成功')
}

export const giveHeatNumber = value => {
    if (value < 1000) {
        return value
    }
    return getBit(value / 10000) + '万'
}

function getBit (value) {
    const reg = /([0-9]+\.[0-9]{1})[0-9]*/
    let str = value.toString()
    str = str.replace(reg, '$1')
    return str
}

export const qulities = {
    Original: '超清',
    HD: '高清',
    ordinary: '一般'
}
export const groupChars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']

export const percentToNum = (value) => {
    if (!value) {
        return
    }
    let res = value
    if (typeof value === 'string' && value.includes('%')) {
        res = value.slice(0, value.length - 1) * 1
    }
    return res
}

export const isIPhoneXOrAbove = () => { // 判断是否是 iphone以上的机器
    const ua = window.navigator.userAgent
    const isIOS = /iP(hone|od|ad)/.test(ua)
    const isIPhoneX = isIOS && (window.devicePixelRatio === 3) && (
        (window.screen.width === 375 && window.screen.height === 812) || // iPhone X, XS, 11 Pro, 12 Mini, 13 Mini
        (window.screen.width === 414 && window.screen.height === 896) || // iPhone XR, XS Max, 11, 11 Pro Max, 12, 12 Pro, 12 Pro Max, 13, 13 Pro, 13 Pro Max, 14, 14 Plus
        (window.screen.width === 390 && window.screen.height === 844) || // iPhone 12, 12 Pro, 13, 13 Pro
        (window.screen.width === 428 && window.screen.height === 926) || // iPhone 12 Pro Max, 13 Pro Max, 14 Pro Max
        (window.screen.width === 430 && window.screen.height === 932) // iPhone 14 Pro Max
    )

    return isIPhoneX
}

export const isBrowser = () => {
    const url = location.href
    if (url.includes('liteapp')) { // 域名自带liteapp识别
        return false
    } else {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera
        return /iPhone|iPad|iPod|Android/i.test(userAgent)
    }
}
